/* eslint-disable no-unused-vars */
import Navbar from './modules/navbar';
import LoadModule from './modules';
import CheckSelector from './utils/checkselector';
import './modules/objectfit';

window.addEventListener('load', () => {
	Navbar();
	//window.alert('hej');
});
